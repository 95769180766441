import {
  ElRow,
  ElCol,
  ElButton,
  ElCard,
  ElIcon,
  ElMessage,
} from "element-plus";

import lang from "element-plus/lib/locale/lang/nl";
import "dayjs/locale/nl";
import locale from "element-plus/lib/locale";

export default (app) => {
  locale.use(lang);
  app.use(ElRow);
  app.use(ElCol);
  app.use(ElButton);
  app.use(ElIcon);
  app.use(ElCard);
  app.use(ElMessage);
};
