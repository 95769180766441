export default {
  logout({ commit }) {
    commit("setUserToken", { token: null });
    commit("setUserRole", { role: null });

    sessionStorage.removeItem("token");
    sessionStorage.removeItem("role");
  },
  checkForStoredToken({ getters, commit }) {
    if (getters.userToken === null) {
      const token = sessionStorage.getItem("token");
      if (token) {
        commit("setUserToken", { token });
      }
    }
  },
  checkForStoredUserRole({ getters, commit }) {
    if (getters.userRole === null) {
      const role = sessionStorage.getItem("role");
      if (role) {
        commit("setUserRole", { role });
      }
    }
  },
};
