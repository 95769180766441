import { createRouter, createWebHistory } from "vue-router";

import store from "../store/index";
import { ElMessage } from "element-plus";

const SubmitRequest = () => import("../views/SubmitRequest");
const Vote = () => import("../views/Vote");
const Login = () => import("../views/Login");
const AdminDashboard = () => import("../views/AdminDashboard");
const DJDashboard = () => import("../views/DJDashboard");
const ScreenPortal = () => import("../views/ScreenPortal");

const guards = {
  authRequired: (to, from, next) => {
    store.dispatch("checkForStoredToken");
    store.dispatch("checkForStoredUserRole");

    if (store.getters.userToken === null) {
      next("/login");
    } else {
      next();
    }
  },
  adminOnly: (to, from, next) => {
    store.dispatch("checkForStoredToken");
    store.dispatch("checkForStoredUserRole");

    if (
      store.getters.userToken === null ||
      store.getters.userRole !== "admin"
    ) {
      next("/login");
    } else {
      next();
    }
  },
};

const routes = [
  {
    path: "/",
    component: SubmitRequest,
  },
  {
    path: "/vote",
    name: "Vote",
    component: Vote,
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
  },
  {
    path: "/admin",
    component: AdminDashboard,
    beforeEnter: guards.adminOnly,
  },
  {
    path: "/dj",
    component: DJDashboard,
    beforeEnter: guards.authRequired,
  },
  {
    path: "/screen",
    component: ScreenPortal,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.afterEach(() => {
  ElMessage.closeAll();
});

export default router;
