<template>
  <el-button
    v-if="loggedIn"
    class="logoutButton"
    type="text"
    icon="el-icon-switch-button"
    size="small"
    @click="logout"
    ><span class="hidden-sm">Log uit</span></el-button
  >
</template>

<script>
export default {
  computed: {
    loggedIn() {
      return this.$store.getters.userToken !== null;
    },
  },
  methods: {
    logout() {
      this.$store.dispatch("logout");
      this.$router.push({
        name: "Login",
        params: { message: "Je bent uitgelogd." },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.logoutButton {
  & button {
    color: #fff;

    & ::v-deep span {
      @include respond(phone) {
        display: none;
      }
    }
  }
}
</style>
