/**
 * Mixin for api calls
 */

export default {
  methods: {
    /**
     *
     * @param {Object} headers
     * @param {String} path
     * @param {String} method
     * @param {Object} queryStringObject
     * @param {Object} payload
     * @returns
     */
    fetchData(headers, path, method, queryStringObject, payload) {
      // Validation
      headers = typeof headers === "object" && headers !== null ? headers : {};

      // If no Content-Type header is provided, default to application/json, unless payload prototype is FormData
      if (
        typeof headers["Content-Type"] !== "string" &&
        payload &&
        payload.constructor !== FormData
      ) {
        headers["Content-Type"] = "application/json";
      }

      path = typeof path === "string" ? path : "/";
      method =
        typeof method === "string" &&
        ["POST", "GET", "PUT", "DELETE"].indexOf(method) > -1
          ? method.toUpperCase()
          : false;
      queryStringObject =
        typeof queryStringObject === "object" && queryStringObject !== null
          ? queryStringObject
          : {};
      payload = typeof payload === "object" && payload !== null ? payload : {};

      // For each query string parameter sent, add it to the path
      let requestUrl = `${path}?`;
      let counter = 0;
      for (let queryKey in queryStringObject) {
        counter++;
        // If at least one query string parameter has been added, prepend new onces with an &
        if (counter > 1) {
          requestUrl += "&";
        }
        // Add the key value
        requestUrl += `${queryKey}=${queryStringObject[queryKey]}`;
      }

      // Construct the request options
      const requestOptions = {
        method,
        headers,
      };

      // If there is a current token set, add it as a header
      if (this.$store.getters.userToken) {
        requestOptions.headers[
          "Authorization"
        ] = `Bearer ${this.$store.getters.userToken}`;
      }

      // Add the payload to the request object
      if (["POST", "PUT"].indexOf(method) > -1) {
        requestOptions.body =
          requestOptions.headers["Content-Type"] === "application/json"
            ? JSON.stringify(payload)
            : payload;
      }

      // Form the http request
      return fetch(
        `${this.$store.getters.getConstant("API_URI")}/${requestUrl}`,
        requestOptions
      )
        .then(async (res) => {
          const statusCode = res.status;
          const parsedJSON = await res.json();

          return { statusCode, data: parsedJSON };
        })
        .catch((err) => {
          return { error: err };
        });
    },
    errorHandler(response) {
      const { statusCode } = response;

      // Unauthorized, (bad token, expired token, etc)
      if (statusCode === 401) {
        // Log the user out and redirect to login page
        this.$store.dispatch("logout");

        if (this.$route.name !== "Login") {
          this.$router.push({
            name: "Login",
            params: { message: "Je bent niet meer ingelogd." },
          });
        }

        return true;
      }

      return false;
    },
  },
};
