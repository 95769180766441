<template>
  <el-switch
    v-if="loggedIn"
    v-model="screen"
    @change="$store.commit('toggleScreen', $event)"
    inactive-icon-class="el-icon-postcard"
    active-icon-class="el-icon-timer"
    active-value="vote"
    inactive-value="request"
    class="screenToggle"
    :width="65"
  ></el-switch>
</template>

<script>
import { ElSwitch } from "element-plus";
export default {
  components: {
    ElSwitch,
  },
  data() {
    return {};
  },
  computed: {
    loggedIn() {
      return this.$store.getters.userToken !== null;
    },
    voteIsActive() {
      return this.$store.getters.activeVote !== null;
    },
    screen: {
      get: function () {
        return this.$store.getters.activeScreen;
      },
      set: function (newVal) {
        this.$store.commit("setActiveScreen", newVal);
        if (newVal === "vote" && this.$store.getters.activeVote) {
          localStorage.setItem(
            "voteScreen",
            this.$store.getters.activeVote._id
          );
        } else {
          localStorage.removeItem("voteScreen");
        }
      },
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-switch__label {
  &:not(.is-active) {
    color: #fff;
  }

  &.is-active {
    // background-color: rgba(#fff, .8);
    color: #fff;
  }

  @include respond(phone) {
    display: none;
  }
}

.screenToggle {
  margin-right: 4rem;
}
</style>
