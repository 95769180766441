import { createStore } from "vuex";

import rootGetters from "./getters";
import rootMutations from "./mutations";
import rootActions from "./actions";

import constants from "./constants";

export default createStore({
  state: {
    constants,
    user: {
      token: null,
      role: null,
    },
    activeVote: null,
    activeScreen: "request",
    dashboardActiveTab: "request",
    userUpdatedScreenStatus: false,
    requestFilters: {
      favorites: false,
      karaokes: false,
      images: false,
    },
  },
  getters: rootGetters,
  mutations: rootMutations,
  actions: rootActions,
  modules: {},
});
