export default {
  setUserToken(state, payload) {
    const { token } = payload;
    state.user.token = token;
  },
  setUserRole(state, payload) {
    const { role } = payload;
    state.user.role = role;
  },
  setActiveVote(state, payload) {
    state.activeVote = payload;
  },
  incrementVoteOption(state, payload) {
    const { id } = payload;

    if (state.activeVote) {
      const index = state.activeVote.options.findIndex(
        (option) => option._id === id
      );

      if (index > -1) {
        state.activeVote.options[index].count++;
      }
    }
  },
  setActiveScreen(state, payload) {
    state.activeScreen = payload;
  },
  toggleScreen(state, payload) {
    state.userUpdatedScreenStatus = true;
    state.activeScreen = payload;
  },
  resetUserUpdateStatus(state) {
    state.userUpdatedScreenStatus = false;
  },
  setFilter(state, { type, value }) {
    state.requestFilters[type] = value;
  },
  setDashboardActiveTab(state, value) {
    state.dashboardActiveTab = value;
  },
};
