<template>
  <transition name="clock-animation">
    <div
      v-if="show"
      v-bind="$attrs"
      :class="{
        'red-text': lessThenFiveMinutes,
        'screen-clock': type === 'screen',
      }"
    >
      {{ formattedTimeLeft }}
    </div>
  </transition>
</template>

<script>
export default {
  props: {
    vote: {
      type: Object,
    },
    show: {
      type: Boolean,
    },
    type: {
      type: String,
    },
  },
  data() {
    return {
      minutesLeft: 0,
      secondsLeft: 0,
    };
  },
  computed: {
    formattedTimeLeft() {
      let minutes = this.minutesLeft.toString();
      minutes = minutes.length < 2 ? `0${minutes}` : minutes;

      let seconds = this.secondsLeft.toString();
      seconds = seconds.length < 2 ? `0${seconds}` : seconds;

      return `${minutes}:${seconds}`;
    },
    lessThenFiveMinutes() {
      return this.minutesLeft < 5;
    },
    timeIsOver() {
      return this.minutesLeft === 0 && this.secondsLeft === 0;
    },
  },
  methods: {
    calculateTime() {
      this.minutesLeft = 0;
      this.secondsLeft = 0;

      const startTime = new Date(this.vote.start_time).getTime();
      const endTime = startTime + this.vote.duration;

      if (Date.now() < endTime) {
        const timeLeft = endTime - Date.now();

        this.minutesLeft = Math.floor(timeLeft / (60 * 1000));
        this.secondsLeft = Math.floor(timeLeft / 1000) % 60;
      }

      this.oncePerSecond(() => {
        if (this.minutesLeft > 0 || this.secondsLeft > 0) {
          if (this.secondsLeft === 0) {
            this.minutesLeft--;
            this.secondsLeft = 59;
          } else {
            this.secondsLeft--;
          }
        } else {
          this.$emit("end");
        }
      });
    },
    oncePerSecond(callback) {
      const timerFunction = () => {
        const now = 1000 * Math.floor(Date.now() / 1000 + 0.1);
        callback();
        if (
          !this.timeIsOver &&
          this.show &&
          this.$store.getters.activeVote !== null
        ) {
          setTimeout(timerFunction, now + 1000 - Date.now());
        } else {
          setTimeout(callback, now + 1000 - Date.now());
        }
      };
      timerFunction();
    },
  },
  watch: {
    vote: {
      immediate: true,
      handler(newVal) {
        if (newVal) {
          this.calculateTime();
        }
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.screen-clock {
  font-size: 6rem;
  font-family: DigitalDream;
  z-index: 9999;
  position: fixed;
  top: 4.2rem;
  right: 2rem;
  background-color: rgba(#fffefa, 1);
  padding: 0.2rem 1rem;
  border: 0.35rem solid $color-black;
  color: $color-black;
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.red-text {
  color: #ad0c03;
}

.clock-animation-enter-active {
  transition: all 0.6s cubic-bezier(0.17, 0.67, 0.74, 0.72);
}
.clock-animation-leave-active {
  transition: all 0.6s cubic-bezier(0.17, 0.67, 0.74, 0.72) 3.5s;
  color: rgba($color-primary, 0.5);
}
.clock-animation-leave-from {
  color: rgba($color-primary, 0.5);
}

.clock-animation-enter-from {
  transform: translateY(-100%);
}

.clock-animation-leave-to {
  transform: translateY(-100%);
}
</style>
