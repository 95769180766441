<template>
  <el-affix @change="handleChange">
    <div class="header" ref="header">
      <div style="margin-right: auto; display: flex; align-items: center">
        <el-switch
          v-show="
            ['/admin', 'dj'].includes($route.path) &&
            dashboardActiveTab === 'request'
          "
          v-model="favFilter"
          active-icon-class="el-icon-star-on"
          style="margin-right: 2rem"
          :width="65"
        ></el-switch>
        <el-button
          v-show="
            ['/admin', 'dj'].includes($route.path) &&
            dashboardActiveTab === 'request'
          "
          size="mini"
          type="warning"
          @click="handleUndoDeleteRequest"
        >
          <div style="display: flex; align-items: center">
            <svg
              height="19px"
              width="19px"
              id="Layer_1"
              style="
                enable-background: new 0 0 512 512;
                stroke: #fff;
                fill: #fff;
                margin-right: 5px;
              "
              version="1.1"
              viewBox="0 0 512 512"
              xml:space="preserve"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
            >
              <g>
                <path
                  d="M447.9,368.2c0-16.8,3.6-83.1-48.7-135.7c-35.2-35.4-80.3-53.4-143.3-56.2V96L64,224l192,128v-79.8   c40,1.1,62.4,9.1,86.7,20c30.9,13.8,55.3,44,75.8,76.6l19.2,31.2H448C448,389.9,447.9,377.1,447.9,368.2z M432.2,361.4   C384.6,280.6,331,256,240,256v64.8L91.9,224.1L240,127.3V192C441,192,432.2,361.4,432.2,361.4z"
                />
              </g>
            </svg>
            <span class="hidden-sm">Undo</span>
          </div>
        </el-button>
        <VoteClock
          v-if="isAdmin"
          :vote="$store.getters.activeVote"
          :show="!!$store.getters.activeVote"
          class="header__clock"
        />
      </div>
      <img
        src="@/assets/img/logoblizzert.png"
        alt="Blizzert logo"
        class="header__logo"
      />
      <screen-toggle v-if="isAdmin" />
      <logout-button />
    </div>
  </el-affix>
</template>

<script>
import LogoutButton from "../LogoutButton";
import ScreenToggle from "../ScreenToggle";
import VoteClock from "../VoteClock";
import { ElAffix, ElSwitch } from "element-plus";
import fetchMixins from "../../mixins/ajax";
export default {
  components: {
    ElAffix,
    ElSwitch,
    LogoutButton,
    ScreenToggle,
    VoteClock,
  },
  mixins: [fetchMixins],
  computed: {
    dashboardActiveTab() {
      return this.$store.getters["dashboardActiveTab"];
    },
    isAdmin() {
      return this.$store.getters.userRole === "admin";
    },
    favFilter: {
      get() {
        return this.$store.getters["requestFilters"].favorites;
      },
      set(value) {
        this.$store.commit("setFilter", { type: "favorites", value });
      },
    },
  },
  methods: {
    handleChange(scrolled) {
      if (scrolled) {
        this.$refs.header.classList.add("scrolled");
      } else {
        this.$refs.header.classList.remove("scrolled");
      }
    },
    async handleUndoDeleteRequest() {
      const response = await this.fetchData(null, "request/revoke", "POST");
      if (response.statusCode !== 200) {
        this.$message(`Er is iets fout gegaan. ${response.data.error}`);
        return;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.header {
  background-color: rgba(10, 10, 10, 0.65);
  height: 6.5rem;
  width: 100%;
  box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.2);
  transition: all 0.2s ease-in-out;

  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0 4rem;

  @include respond(phone) {
    padding: 0 2rem;
  }

  &__logo {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 1rem;
    height: 11rem;

    //    @include respond(phone) {
    //   height: 8rem;
    // }
  }

  &__clock {
    color: #fff !important;
    font-size: 2rem;
    margin-right: auto;
  }

  & button {
    color: #fff;

    // & ::v-deep span {
    //   @include respond(phone) {
    //     // display: none;
    //   }
    // }
  }
}

.scrolled {
  background-color: #fff;

  & ::v-deep .logoutButton {
    color: $color-primary;
  }
  & .header__clock {
    color: $color-black !important;
  }
}

::v-deep .el-switch__label {
  &:not(.is-active) {
    color: #fff;
  }

  &.is-active {
    // background-color: rgba(#fff, .8);
    color: #fff;
  }

  @include respond(phone) {
    display: none;
  }
}
</style>
