<template>
  <div>
    <the-header v-if="showHeader"></the-header>

    <el-container>
      <el-main>
        <router-view></router-view>
      </el-main>
    </el-container>
  </div>
</template>

<script>
import { ElContainer, ElMain } from "element-plus";
import TheHeader from "./components/layout/TheHeader";

export default {
  name: "App",
  components: {
    ElContainer,
    ElMain,
    TheHeader,
  },
  computed: {
    showHeader() {
      return this.$route.path !== "/screen";
    },
  },
};
</script>

<style lang="scss">
@font-face {
  font-family: Futura;
  src: url("~@/assets/fonts/Futura XBlk BT.ttf");
}
@font-face {
  font-family: DigitalDream;
  src: url("~@/assets/fonts/digital-dream.fat.ttf");
}
@font-face {
  font-family: BebasNeue;
  src: url("~@/assets/fonts/BebasNeue-Regular.ttf");
}
@font-face {
  font-family: NulShock;
  src: url("~@/assets/fonts/nulshock_bd.otf");
}

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

html {
  font-size: 62.5%;
  overflow-x: hidden;

  @include respond(tab-land) {
    // width < 1200?
    font-size: 56.25%; //1 rem = 9px, 9/16 = 50%
  }

  @include respond(tab-port) {
    // width < 900?
    font-size: 50%; //1 rem = 8px, 8/16 = 50%
  }

  @include respond(big-desktop) {
    font-size: 75%; //1rem = 12, 12/16
  }
}

body {
  box-sizing: border-box;
  font-size: $default-font-size;
  line-height: 1.5;
}
#app {
  font-family: "Lato", Helvetica, Arial, Helvetica, sans-serif;
  color: $color-black;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Montserrat", Arial, Helvetica, sans-serif;
}

h1 {
  font-size: 2.2rem;
  margin-bottom: 0.8rem;
}

.el-main {
  margin-top: 7rem;
}

i {
  font-size: $default-font-size !important;
}

button {
  font-size: $default-font-size !important;
}

.card {
  max-width: 100rem;
  margin: 0 auto;
  position: relative;

  &__heading {
    text-transform: uppercase;
  }

  &__description {
    color: $color-light-gray;
    margin-bottom: 2rem;
  }

  &--medium {
    max-width: 75rem;
  }

  &--small {
    max-width: 50rem;
  }
}

.section {
  margin-bottom: 4rem;
}

.text-primary {
  color: $color-primary !important;
}

.text-center {
  text-align: center;
}

// Tables general
.pagination-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 2rem;
}

// Switch styling
.el-switch {
  height: 26px;
  line-height: 26px;

  &.is-checked .el-switch__core .el-switch__action {
    margin-left: -26px;
  }

  &.is-disabled {
    opacity: 0.4;
  }

  &__core {
    height: 28px;

    & .el-switch__action {
      width: 24px;
      height: 24px;
    }
  }
}
.hidden-sm {
  @include respond(phone) {
    display: none;
  }
}
</style>
