export default {
  getConstant: (state) => (key) => {
    return state.constants[key];
  },
  userRole: (state) => {
    return state.user.role;
  },
  userToken: (state) => {
    return state.user.token;
  },
  activeVote: (state) => {
    return state.activeVote;
  },
  activeScreen: (state) => {
    return state.activeScreen;
  },
  userUpdatedScreenStatus: (state) => {
    return state.userUpdatedScreenStatus;
  },
  requestFilters: (state) => state.requestFilters,
  dashboardActiveTab: (state) => state.dashboardActiveTab,
};
